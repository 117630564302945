import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

const PrivateRoutes = () => {
	const { token } = useAuth();
	return (
		token ? <Outlet /> : <Navigate to='/login' />
	)
}

type Props = {}

// Lazy loding
const Layout = React.lazy(() => import('./pages/utils/layouts/Layout'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const LogIn = React.lazy(() => import('./pages/auth/Login'));
const ResendVerificationEmail = React.lazy(() => import('./pages/auth/ResendVerificationEmail'));
const ForgetPassword = React.lazy(() => import('./pages/auth/ForgetPassword'));
const Home = React.lazy(() => import('./pages/Home'));
const Transactions = React.lazy(() => import('./pages/transactions/Transactions'));
const AccountPayment = React.lazy(() => import('./pages/transactions/AccountPayment'));
const MakePayment = React.lazy(() => import('./pages/transactions/MakePayment'));
const Assets = React.lazy(() => import('./pages/assets/Index'));
const MutualFunds = React.lazy(() => import('./pages/assets/MutualFund'));
const MutualFundPlus = React.lazy(() => import('./pages/assets/MutualFundPlus'));
const MutualFundHistory = React.lazy(() => import('./pages/assets/MutualFundHistory'));
const AccountView = React.lazy(() => import('./pages/assets/AccountView'));
const Profile = React.lazy(() => import('./pages/account/profile'));
const ProfileBasicDetails = React.lazy(() => import('./pages/account/basic-details'));
const ProfileContactDetails = React.lazy(() => import('./pages/account/contact-details'));
const Password = React.lazy(() => import('./pages/account/password'));

const RouteConfig = (props: Props) => {
	return (
		<Routes > 
			<Route path='/' element={<LogIn />} />
			<Route path='/register' element={<Register />}/>
			<Route path='/sign-up/email-verification' element={<LogIn />} />
			<Route path='/login' element={<LogIn />}/>
			<Route path='/forget-password' element={<ForgetPassword />} />
			<Route path='/resend-verification-email' element={<ResendVerificationEmail />} />
			<Route element={<PrivateRoutes />}>
				<Route element={<Layout />}>
					<Route path='/home' element={<Home />} />
					<Route path='/transactions' element={<Transactions />} />
					<Route path='/payment' element={<AccountPayment />} />
					<Route path='/assets' element={<Assets />} />
					<Route path='/asset/:assetId' element={<AccountView />} />
				</Route>
				<Route path='/mutual-funds' element={<MutualFunds />} />
				<Route path='/mutual-fund-plus' element={<MutualFundPlus />} />
				<Route path='/mf-transaction-history' element={<MutualFundHistory />} />
				<Route path='/profile' element={<Profile />} />
				<Route path='/profile/password' element={<Password/>} />
				<Route path='/profile/basic-details' element={<ProfileBasicDetails />} />
				<Route path='/profile/contact-details' element={<ProfileContactDetails />} />
				<Route path='/payment/self-account' element={<AccountPayment />} />
				<Route path='/payment/utility' element={<MakePayment />} />
			</Route>
		</Routes>
	)
}

export default RouteConfig